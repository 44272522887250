// Here you can add other styles

// custom .chartjs-tooltip-body-item padding
@import "charts";

// custom tweaks for scrollbar styling (wip)
@import "scrollbar";


.form-group {
  margin-bottom: 1rem;
  label {
    margin-bottom: 0.5rem;
  }
}

// custom calendar today cell color
.calendar-cell.today {
  --cui-calendar-cell-today-color: var(--cui-info) !important;
}

// custom select week cursor pointer
.select-week .calendar-row.current {
  cursor: pointer;
}
// RoadMate custom styles


.color-transition {
  transition: color 1.5s;
}

.signal-change {
  color: orange!important;
  font-weight: bold;
}

.text-bg-primary {
  color: rgba(255,255,255,.87)!important;
  background-color: #395F90!important;
}
.extension-img-width  {
  width: 35px;
  height: auto;
}
body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-treenode-label {
  width: 100%;
}
.ui-tree {
  width: 100%!important;
}
.bg-transparent {
  background: transparent;
}
.red {
  color: #d93737
}
.green {
  color: #4aac67;
}
.grey {
  color: #8c8c8c;
}
.orange {
  color: #f6960b;
}
.rm-blue {
  color:#0F4B9A;
}
.rm-clickable {
  cursor: pointer;
}
.tab-content {
  overflow: auto;
}

.rm-icon-cell {
  display: flex;
  justify-content: center;
  align-items: center;
}
.nav-group-items {
  border-top: 2px solid var(--cui-sidebar-nav-link-active-bg);
  border-bottom: 2px solid var(--cui-sidebar-nav-link-active-bg);
  border-left: 2px solid var(--cui-sidebar-nav-link-active-bg);
  border-bottom-left-radius: 5%;
  border-top-left-radius: 5%;
}
.icon-height {
  height: 20px;
}

.bg-gradient-0 {
  background: #395F90 !important;
  background: linear-gradient(45deg, #395F90 0%, #3e6392 100%) !important;
  border-color: #395F90 !important;
}
.bg-gradient-1 {
  background: #f6960b !important;
  background: linear-gradient(45deg, #f6960b 0%, #f3a639 100%) !important;
  border-color: #f6960b !important;
}
.bg-gradient-2 {
  background: #d93737 !important;
  background: linear-gradient(45deg, #d93737 0%, #e45959 100%) !important;
  border-color: #d93737 !important;
}
.bg-gradient-3 {
  background: #3194F2 !important;
  background: linear-gradient(45deg, #3194F2 0%, #408cd3 100%) !important;
  border-color: #3194F2 !important;
}
.bg-gradient-4 {
  background: #4aac67 !important;
  background: linear-gradient(45deg, #4aac67 0%, #1ed856 100%) !important;
  border-color: #4aac67 !important;
}
.bg-gradient-5 {
  background: #715bb9 !important;
  background: linear-gradient(45deg, #715bb9 0%, #624aaf 100%) !important;
  border-color: #715bb9 !important;
}
.bg-gradient-6 {
  background: #e7439d !important;
  background: linear-gradient(45deg, #e7439d 0%, #cf117a 100%) !important;
  border-color: #e7439d !important;
}
.bg-gradient-7 {
  background: #50D2C2 !important;
  background: linear-gradient(45deg, #50D2C2 0%, #139788 100%) !important;
  border-color: #50D2C2 !important;
}
.tab-content {
  min-height: 50vh;
}

label {
  .rm-tooltip {
    position: relative;
    display: block;
    width:200px;
    /* Tooltip text */
    .rm-tooltiptext {
      visibility: hidden;
      width: 100%;
      background-color: rgba(47,53,58,0.9);
      color: #fff;
      text-align: left;
      padding: 5px 0;
      border-radius: 6px;
      padding: 5px;
      /* Position the tooltip text */
      position: absolute;
      z-index: 1;
      margin-top: -55px;
      top: 0;
      left: 100%;
      margin-left: -50%;
      opacity: 0;
      transition: opacity 0.8s;
    }
    .rm-tooltiptext::after {
      content: "";
      position: absolute;
      top: 15%;
      left: -5px;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color:#2f353a transparent transparent transparent;
      transform: rotate(90deg);
    }
  }
  &:hover {
    .rm-tooltip {
      .rm-tooltiptext {
        visibility: visible!important;
        opacity: 1!important;
      }
    }
  }
}
.rm-hide {
  display: none!important;
}
.collapsible {
  overflow: hidden;
  transition: max-height 0.5s ease-in-out; //0.5s ease-out
  max-height: 1000px;
  // transform: scaleY(1);
  transform-origin: top;
}
.collapsed {
  // transform: scaleY(0);
  max-height: 0;
}

.rm-collapsible {
  max-height: 500px;
  height: auto;
  transition: max-height 0.15s ease-out;
  overflow: hidden;
}
.unselectable {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.rm-collaped {
  max-height: 0;
  transition: max-height 0.25s ease-in;
}
.rm-column{
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  button {
    margin-bottom: 5px;
  }
}
.form-control {
  height: auto;
}

.smaller-text {
  font-size: 0.8em;
}

.ng2-smart-pagination-nav {
  margin-top: 20px;
}

.rm-tooltip {
  width: 350px;
  height: fit-content;
}
.my-50 {
  margin-top: 50px;
  margin-bottom: 50px;
}
.sidebar-footer {
  padding: 14px 20px;
}
.rm-row{
  display: flex!important;
  flex-flow: row wrap!important;
  flex-direction: row;
  &.with-tb-pads {
    padding: 10px 0 10px 0;
  }
  &.dispatch-away {
    justify-content: space-between;
    align-items: center;
    align-content: center;
  }
  &.dispatch-evenly {
    justify-content: space-around;
  }
  &.aligncenter {

  }
  &.start {
    justify-content: start;
    align-items: center;
    * {
      margin-right: 10px;
    }
    mat-form-field {
      margin-right: 10px;
    }
  }
  &.end {
    justify-content: flex-end;
    mat-form-field {
      margin-right: 10px;
    }
  }
  &.center {
    justify-content: center;
    align-items: center;
    align-content: center;
  }
  &.spread-space {
    justify-content: space-around;
    align-items: center;
    align-content: space-around;
  }
  &.v-centered {
    align-items: center;
  }
}
.rm-badge-space {
  position: relative;
  .rm-feature-badge {
    position: absolute;
    top: -10px;
    right: 4px;
  }
}
.add-dividing-line {
  position: relative;
}
/* Main div for border to extend to 50% from 25% of bottom right corner */
.add-dividing-line:after {
  content: "";
  background: #ddd;
  position: absolute;
  bottom: 25%;
  right: 0;
  height: 50%;
  width: 1px;
}
.illustration {
  width: 100%;
  height: auto;
  padding: 10px 10px;
  svg {
    width: 100%;
  }
}
.title-with-gradiant-bg {
  background: -webkit-gradient(linear,left top,right top,from(#cf8d7c),to(#a77cb4));
  background: -webkit-linear-gradient(left,#cf8d7c,#a77cb4);
  background: linear-gradient(90deg,#cf8d7c,#a77cb4);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  -webkit-text-fill-color: transparent;
}
.rm-grid {
  display: grid;
  grid-template-rows: auto;
  box-sizing: border-box;
  align-items: center;
  &.align-v {
    align-items: center;
  }
  &.one-fr-2 {
    grid-template-columns: 1fr 1fr;
    grid-gap: 0.5em;
  }
  &.one-fr-3 {
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 0.5em;
  }
  &.three-equal-columns {
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 0.5em;
  }
  &.four-equal-columns {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 0.5em;
  }
  &.five-non-equal-columns {
    grid-template-columns: 2fr 2fr 3fr 1fr 1fr;
    grid-gap: 0.5em;
  }
  &.split-fit-250 {
    grid-gap: 2em;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }
  &.split-fit-150 {
    grid-gap: 2em;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }
  &.split-fill-half {
    grid-gap: 1em;
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  }
  &.split-fill-350 {
    grid-gap: 2em;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  }
  &.split-fill-450 {
    grid-gap: 2em;
    grid-template-columns: repeat(auto-fill, minmax(450px, 1fr));
  }
  &.split-equals-3 {
    grid-gap: 2em;
    grid-template-columns: repeat(auto-fit, minmax(33%, 1fr));
  }
  &.split-equals-4 {
    grid-gap: 2em;
    grid-template-columns: repeat(auto-fit, minmax(25%, 1fr));
  }
}
// input.ng-invalid, select.ng-invalid {
//   border-color: #f86c6b;
//   padding-right: calc(2.5em + .75rem);
//   background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23f86c6b' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23f86c6b' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
//   background-repeat: no-repeat;
//   background-position: 98% calc(0.5em + .1875rem);
//   background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
// }

.page-link-prev, .page-link-next {
  font-size: 1em!important;
}
// input.ng-valid, select.ng-valid:not(.no-verify) {
//   border-color: #4dbd74;
//   padding-right: calc(2.5em + .75rem);
//   background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%234dbd74' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
//   background-repeat: no-repeat;
//   background-position: 98% calc(0.5em + .1875rem);
//   background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
// }

.ng2-smart-filters input.ng-valid, .ng-valid.no-verify {
  background-image: none;
  background-position: 0 0;
  background-size: auto auto;
}

.container-center-v {
  display: flex;
  justify-content: center;
  align-items: center;
}

.container-center-left {
display: flex;
justify-content: start;
align-items: center;
width: 100%;
height: 100%;
}
.container-center-right {
  display: flex;
  justify-content: end;
  align-items: center;
  width: 100%;
  height: 100%;
}
.center {
text-align: center;
}
.left {
text-align: left;
}
.right {
text-align: right;
}
.little-push-down {
  margin-bottom: 10px;
  }
.push-down {
margin-bottom: 20px;
}
.push-down-100 {
  margin-bottom: 100px;
}
.push-right {
  margin-right: 20px;
}
.push-left {
  margin-left: 20px;
}
.pad-t-25 {
padding-top: 25px;
}
.pad-10 {
padding: 10px;
}
.pad {
padding: 20px;
}
.pad-25 {
  padding: 25px;
}
.footer-btn {
padding: 15px;
margin-top: 25px;
}

.detached {
margin-top: 20px;
  &.btm {
    margin-bottom: 20px;
  }
}
.detached2x {
margin-top: 40px;
  &.btm {
    margin-bottom: 40px;
  }
}
.detached3x {
margin-top: 60px;
  &.btm {
    margin-bottom: 60px;
  }
}

.titleh1 {
font: 400 24px/32px Roboto, "Helvetica Neue", sans-serif;
}
.titleh3 {
font: 400 1.2em Roboto, "Helvetica Neue", sans-serif;
}

.app-header  {
  .navbar-brand{
    width: 200px;
    background-color: #161718; //#2f353a
  }
}

.full-width {
  width: 100%;
}
p-calendar > span {
  width: 100%;
}
.m-auto {
  margin: auto;
}
.max-two-lines {
  display: block;/* or inline-block */
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  max-height: 3.6em;
  line-height: 1.8em;
}
.max-three-lines {
  display: block;/* or inline-block */
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  max-height: 5.4em;
  line-height: 1.8em;
}
.max-four-lines {
  display: block;/* or inline-block */
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  max-height: 7.2em;
  line-height: 1.8em;
}
.ui-carousel-items-container .ui-carousel-item {
  overflow: hidden!important;
}

.wrap {
  text-wrap: balance;
}
.max-five-lines {
  display: block;/* or inline-block */
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  max-height: 9em;
  line-height: 1.8em;
}
.toast-bottom-full-width {
  position: fixed;
  z-index: 999999;
  bottom: 0;
  right: 0;
  width: 100%;
  .toast-info {
    width: 96%;
    margin: auto;
    background-color: #2f353a;
    opacity: 1 !important;
    color: white;
    position: relative;
    overflow: hidden;
    padding: 15px;
    width: 300px;
    border-radius: .25rem;
    background-position: 15px center;
    background-repeat: no-repeat;
    .toast-title {
      font-weight: bold;
    }
  }
}
.relative {
  position: relative;
}
.bold {
  font-weight: bold;
}
.bolder {
  font-weight: bolder;
}
.clickable {
  cursor: pointer;
}
.light-shadow {
  outline: 0;
  box-shadow: 2px 2px 0 0 rgba(50,31,9,.25)
}
.rm-relative {
  position: relative;
}
.w100 {
  width: 100%;
}
.h100 {
  height: 100%;
}
.hw100 {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  display: block;
  position: relative;
}
.rm-absolute {
  position: absolute;
  &.rm-top-right {
    top: 0px;
    right: 0px;
  }
  &.rm-top-left {
    top: 0px;
    left: 0px;
  }
  &.rm-bottom-right {
    bottom: 0px;
    right: 0px;
  }
  &.rm-bottom-left {
    bottom: 0px;
    left: 0px;
  }
}
.nav-group-items {
  background-color: #EDEDED60;
}
.rm-autocomplete-form {
  .c-multi-select-options {
    background: #FFF!important;
    padding: 5px 45px!important;
    border-radius: 5px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)!important;
  }
  .c-multi-select-options {
    .c-multi-select-option {
      padding: 5px 5px!important;
    }
  }
}
.hidden {
  visibility: hidden;
}
.ml-max {
  margin-left: auto;
}
.mr-max {
  margin-right: auto;
}
.mt-max {
  margin-top: auto;
}
.mb-max {
  margin-bottom: auto;
}
.txt-white {
  color: white;
}
.ng2-smart-pagination[_ngcontent-sye-c18] .page-link-next[_ngcontent-sye-c18], .ng2-smart-pagination[_ngcontent-sye-c18] .page-link-prev[_ngcontent-sye-c18] {
  font-size: 14px!important;
}

.font-2em {
  font-size: 2em;
}

.font-3em {
  font-size: 3em;
}

.font-1-5em {
  font-size: 1.5em;
}

.rm-selected {
  background-color: $roadmate-primary-color!important;
  color: white!important;
}

$table-font-color: #606c71;
$table-border-color: #e9ebec;

ng2-smart-table {
    table{
      color: $table-font-color;
      tr {
        cursor: pointer;
        td,th{
          border: 1px solid $table-border-color;
        }
      }
    }
}

@media (min-width: 1200px) {
  .modal-xl {
      --cui-modal-width: 90vw;
      width: 90vw!important;
  }
  .modal-lg {
    --cui-modal-width: 70vw;
    width: 70vw!important;
  }
  .modal-sm {
    --cui-modal-width: 50vw;
    width: 50vw!important;
  }
}
@media screen and (min-width: 900px) {
  .mobile-only {
    display: none!important;
  }
}
@media screen and (max-width: 500px) {
  .desktop-only {
    display: none!important;
  }
  .navbar-brand {
    background: transparent!important;
  }
  .card-body {
    padding: 5px!important;
  }
}

.card.shadow-selected {
  position:relative;
  -webkit-box-shadow:0 6px 6px rgba(17, 99, 146, 0.5), 0 0 40px rgba(17, 99, 146, 0.6) inset;
  -moz-box-shadow:0 6px 6px rgba(17, 99, 146, 0.5), 0 0 40px rgba(17, 99, 146, 0.6) inset;
  box-shadow:0 6px 6px rgba(17, 99, 146, 0.5), 0 0 40px rgba(17, 99, 146, 0.6) inset;
}

.rm-table-scroll {
  width: 100%;
  overflow-x: auto;
  display: block;
  height: fit-content;
  margin-bottom: 150px;
}

@keyframes blink {
  from { opacity: 1.0; }
  50% { opacity: 0.5; }
  to { opacity: 1.0; }
}

@-webkit-keyframes blink {
  from { opacity: 1.0; }
  50% { opacity: 0.5; }
  to { opacity: 1.0; }
}

.rm-blink {
  animation:blink 1000ms infinite;
  -webkit-animation:fade 1000ms infinite;
}

p-calendar {
  display: block;
  .p-calendar {
    width: 100%;
    input {
      height: calc(1.5em + 0.75rem + 2px);
      padding: 0.375rem 0.75rem;
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.5;
      color: #5c6873;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #e4e7ea;
      border-radius: 0.25rem;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }
  }
}
.bg-fmd {
  background: #395F90!important;
  background: linear-gradient(45deg,#395F90 0%,#0F4B9A 100%)!important;
  border-color: #395F90!important;
  margin-bottom: 15px;
}

.bg-cm {
  background: #0F4B9A!important;
  background: linear-gradient(-45deg,#0F4B9A 0%,#0a346b 100%)!important;
  border-color: #0f3057!important;
  margin-bottom: 15px;
}

.underline {
  text-decoration: underline;
}

.bg-dp {
  background: #0a346b!important;
  background: linear-gradient(45deg,#0a346b 0%,#091d37 100%)!important;
  border-color: #0a346b!important;
}

.bg-payment {
  background: #091d37!important;
  background: linear-gradient(45deg,#091d37 0%,#03070b 100%)!important;
  border-color: #000!important;
  margin-bottom: 15px;
}

// :root {
//   @media screen and (min-width: 900px) {
//     --cui-sidebar-occupy-start: 0rem !important;
//   }
// }