// Variable overrides
//
// If you want to customize your project please add your variables below.

// $enable-deprecation-messages: false !default;
$roadmate-primary-color: #0F4B9A;
$roadmate-primary-stronger-color: #0F4B9A;
$theme-colors: (
  "primary": $roadmate-primary-color,
  "secondary": #395F90,
  "success": #28a745,
  "info": #17a2b8,
  "warning": #ffc107,
  "danger": #dc3545,
  "light": #f8f9fa,
  "dark": #343a40,
);
@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
      max-width: 100%!important;
  }
}
:root {
  --main-roadmate-color: #0F4B9A;
  --main-roadmate-hover-color: #0F4B9A;
  --main-roadmate-disabled-color: #49668d67;
  --main-secondary-color: #15adad;
  --main-secondary-hover-color: #2a9595;
  --main-secondary-disabled-color: #5a95958c;
  --main-roadmate-grey: #374254;
  --title-color-hx: #656F81;
  .table-light {
    --cui-table-color: #0F4B9A;
  }
  a {
    color: var(--main-roadmate-color);
  }
  body{
    h2, h3, h4, h5, h6 {
      font-weight: 200;
      text-wrap: balance;
      color: var(--title-color-hx)
    }
    h1 {
      font-weight: 500;
      text-wrap: balance;
      margin-bottom: 35px;
      color: var(--title-color-hx)
    }
    &.roadmate {
      --cui-body-font-family: -apple-system, Roboto, system-ui, "Segoe UI", "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      --cui-body-font-size: .975rem;
      --cui-body-font-weight: 400;
      --cui-body-line-height: line-height: 1.5;
      --cui-tertiary-bg: #F0F4F7;
      .bg-light {
        --cui-bg-opacity: 1;
        background-color: #F0F4F7 !important;
      }
      .btn-outline-primary {
        --cui-btn-color: var(--main-roadmate-color);
        --cui-btn-border-color: var(--main-roadmate-color);
        --cui-btn-hover-color: #fff;
        --cui-btn-hover-bg: var(--main-roadmate-hover-color);
        --cui-btn-hover-border-color: var(--main-roadmate-hover-color);;
        --cui-btn-focus-shadow-rgb: 88, 86, 214;
        --cui-btn-active-color: #fff;
        --cui-btn-active-bg: var(--main-roadmate-hover-color);
        --cui-btn-active-border-color: var(--main-roadmate-hover-color);
        --cui-btn-active-shadow: inset 0 3px 5px rgba(8, 10, 12, 0.125);
        --cui-btn-disabled-color: var(--main-roadmate-disabled-color);
        --cui-btn-disabled-bg: transparent;
        --cui-gradient: none;
      }
      .btn-primary {
          --cui-btn-color: #fff;
        --cui-btn-bg: var(--main-roadmate-color);
        --cui-btn-border-color: var(--main-roadmate-color);
        --cui-btn-hover-color: #fff;
        --cui-btn-hover-bg: var(--main-roadmate-hover-color);
        --cui-btn-hover-border-color: var(--main-roadmate-hover-color);
        --cui-btn-focus-shadow-rgb: 113, 111, 220;
        --cui-btn-active-color: #fff;
        --cui-btn-active-bg: var(--main-roadmate-hover-color);
        --cui-btn-active-border-color: var(--main-roadmate-hover-color);
        --cui-btn-active-shadow: inset 0 3px 5px rgba(8, 10, 12, 0.125);
        --cui-btn-disabled-color: #fff;
        --cui-btn-disabled-bg: var(--main-roadmate-disabled-color);
        --cui-btn-disabled-border-color: var(--main-roadmate-disabled-color);
      }
      .btn-secondary {
        --cui-btn-color: var(--main-roadmate-color);
        --cui-btn-bg: transparent;
        --cui-btn-border-color: var(--main-roadmate-color);
        --cui-btn-hover-color: #fff;
        --cui-btn-hover-bg: var(--main-roadmate-hover-color);
        --cui-btn-hover-border-color: var(--main-roadmate-hover-color);
        --cui-btn-focus-shadow-rgb: 129, 139, 151;
        --cui-btn-active-color: #fff;
        --cui-btn-active-bg: var(--main-roadmate-hover-color);
        --cui-btn-active-border-color: var(--main-roadmate-hover-color);
        --cui-btn-active-shadow: inset 0 3px 5px rgba(8, 10, 12, 0.125);
        --cui-btn-disabled-color: #fff;
        --cui-btn-disabled-bg: var(--main-roadmate-disabled-color);
        --cui-btn-disabled-border-color: var(--main-roadmate-disabled-color);
      }
      .btn-danger {
        --cui-btn-color: #FFFFFF;
        --cui-btn-bg: #e55353;
        --cui-btn-border-color: #e55353;
        --cui-btn-hover-color: #FFFFFF;
        --cui-btn-hover-bg: #e96d6d;
        --cui-btn-hover-border-color: #e86464;
        --cui-btn-focus-shadow-rgb: 196, 72, 72;
        --cui-btn-active-color: #FFFFFF;
        --cui-btn-active-bg: #ea7575;
        --cui-btn-active-border-color: #e86464;
        --cui-btn-active-shadow: inset 0 3px 5px rgba(8, 10, 12, 0.125);
        --cui-btn-disabled-color: #d4d4d4;
        --cui-btn-disabled-bg: #e55353;
        --cui-btn-disabled-border-color: #e55353;
      }
      --cui-primary: var(--main-roadmate-color);
      --cui-secondary: var(--main-secondary-color);
      --cui-primary-rgb: 15, 75, 154;
      --cui-secondary-rgb: 57, 95, 144;
      
      --cui-font-sans-serif: -apple-system, Roboto, system-ui, "Segoe UI", "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      --cui-font-monospace: -apple-system, Roboto, system-ui, "Segoe UI", "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      // --cui-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
      // --cui-body-font-family: var(--cui-font-sans-serif);
      // --cui-body-font-size: 1rem;
      // --cui-body-font-weight: 400;
      // --cui-body-line-height: 1.5;
      // --cui-body-color: rgba(44, 56, 74, 0.95);
      // --cui-body-bg: #fff;
      // --cui-border-width: 1px;
      // --cui-border-style: solid;
      // --cui-border-color: #d8dbe0;
      // --cui-border-color-translucent: rgba(0, 0, 21, 0.175);
      // --cui-border-radius: 0.375rem;
      // --cui-border-radius-sm: 0.25rem;
      // --cui-border-radius-lg: 0.5rem;
      // --cui-border-radius-xl: 1rem;
      // --cui-border-radius-2xl: 2rem;
      // --cui-border-radius-pill: 50rem;
      // --cui-heading-color: unset;
      // --cui-link-color: #243b8efc;
      // --cui-link-hover-color: #463d9c;
      // --cui-code-color: #d63384;
      // --cui-highlight-bg: #fff3cd;
    }
  }
}

[data-coreui-theme="dark"] {
  --main-roadmate-color: #F4F2FA;
  --main-roadmate-grey: #9DA5B1;
  --cui-table-color: #6e7f96;
  --title-color-hx:#F4F2FA;
}